import { call, apply, select, takeLatest } from 'redux-saga/effects';
import fetch from 'isomorphic-fetch';
import configs from '../configs';

function* login() {
	try {
		let state = yield select();
		let credential = window.btoa(`${state.username}:${state.password}`);
		let response = yield call(fetch, `${configs.URL_ROOT}/token`, {
			method : 'GET',
			headers : {
				Authorization : `Basic ${credential}`
			}
		});

		let payload = yield apply(response, response.json);
		if (!response.ok)
			throw payload;

		localStorage.setItem('token', payload.token);
		window.location.assign(`${configs.URL_ROOT}/home`);

	} catch(err) {
		window.alert(err.message);
	}
}

function* actionSaga() {
	yield takeLatest('LOGIN', login);
}

export default actionSaga;
