export default (state, action) => {
	let { payload, type } = action;
	switch (type) {
	case 'ENTER_USERNAME':
		return { ...state, username : payload.username };
	case 'ENTER_PASSWORD':
		return { ...state, password : payload.password };
	case 'CLEAR':
		return { ...state, username : '', password : ''};
	case 'LOGIN':
		return { ...state, loading : true };
	default:
		return state;
	}
};
