import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './reducer';
import actionSaga from './actionSaga';


const sagaMiddleware = createSagaMiddleware();

function configureStore(initialState = {}) {
	const store = createStore(
		reducer,
		initialState,
		applyMiddleware(sagaMiddleware)
	);
	sagaMiddleware.run(actionSaga);
	return store;
}
export default configureStore;
