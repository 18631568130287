import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import { Provider, connect } from 'react-redux';
import { createSelector } from 'reselect';

import { hot } from 'react-hot-loader';

import {Responsive, WidthProvider} from 'react-grid-layout';
import injectTapEventPlugin from 'react-tap-event-plugin';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import muiTheme from '../muiTheme';
import { RaisedButton, TextField, Card, CardTitle, CardActions, CardText } from 'material-ui';

import { ENTER_USERNAME, ENTER_PASSWORD, CLEAR, LOGIN} from './actions';
import configureStore from './store';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
injectTapEventPlugin();

let LoginForm = props => {
	const usernameField = (
		<TextField
			id={Math.random().toString(31).substring(2,10)}
			fullWidth
			name="username"
			type="text"
			placeholder="username"
			value={props.username}
			onChange={props.enterUsername}
		/>
	);
	const passwordField = (
		<TextField
			id={Math.random().toString(31).substring(2, 10)}
			fullWidth
			name="password"
			type="password"
			placeholder="password"
			value={props.password}
			onChange={props.enterPassword}
			onKeyDown={e => {
				if (e.keyCode == 13 || e.which == 13 || e.keyCode == 10)
					props.login();
			}}
		/>
	);

	const control = (
		<CardActions>
			<RaisedButton primary onTouchTap={props.login} label="Login" />
			<RaisedButton onTouchTap={props.clearForm} label="Clear"/>
		</CardActions>
	);
	return (
		<div>
			<CardTitle title="Build-in-PRO" />
			<CardText>
				{usernameField}
				<br/>
				{passwordField}
			</CardText>
			<center>
				{control}
			</center>
		</div>
	);
};
LoginForm.propTypes = {
	username      : PropTypes.string.isRequired,
	password      : PropTypes.string.isRequired,
	enterUsername : PropTypes.func.isRequired,
	enterPassword : PropTypes.func.isRequired,
	login         : PropTypes.func.isRequired,
	clearForm     : PropTypes.func.isRequired
};

const mapStateToPureComponent = createSelector(
	state => state.username,
	state => state.password,
	(username, password) => ({ username, password })
);

const mapDispatchToPureComponent = dispatch => ({
	enterUsername : event => dispatch(ENTER_USERNAME({ username : event.target.value })),
	enterPassword : event => dispatch(ENTER_PASSWORD({ password : event.target.value })),
	login         : () => dispatch(LOGIN()),
	clearForm     : () => dispatch(CLEAR())
});

let ConnLoginForm = connect(
	mapStateToPureComponent,
	mapDispatchToPureComponent
)(LoginForm);

let token = localStorage.getItem('token') || '';
const initialState = {
	username : '',
	password : '',
	token,
	authenticated : !!token,
};

let store = configureStore(initialState);

const layouts = {
	lg : [
		{ i : 'loginForm', x: 4.5, y: 1, w: 3, h: 7, static : true }
	],
	md : [
		{ i : 'loginForm', x: 3.5, y: 1, w: 3, h: 7, static : true }
	],
	sm : [
		{ i : 'loginForm', x: 1, y: 0, w: 6, h: 7, static : true }
	],
	xs : [
		{ i : 'loginForm', x: 0, y: 0, w: 6, h: 7, static : true }
	]
};

let App = () => (
	<Provider store={store}>
		<MuiThemeProvider muiTheme={muiTheme}>
			<ResponsiveReactGridLayout layouts={layouts}
				breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
				verticalCompact={false}
				rowHeight={30}
				cols={{ lg: 12, md: 10, sm: 8, xs: 6, xxs: 6 }}>
				<Card key="loginForm">
					<ConnLoginForm />
				</Card>
			</ResponsiveReactGridLayout>
		</MuiThemeProvider>
	</Provider>
);

App = hot(module)(App);

render(
	<App/>,
	document.getElementById('loginForm')
);
